import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Content, { HTMLContent } from '../components/Content'
import Layout from '../components/Layout'

export const PrivacyPolicyTemplate = ({ content, contentComponent }) => {
  const PageContent = contentComponent || Content

  return (
    <div className="content">
      <section className="section section--gradient">
        <div className="container">
          <div className="columns">
            <div className="column is-10 is-offset-1">
              <div className="section">
                <PageContent className="content" content={content} />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

PrivacyPolicyTemplate.propTypes = {
  content: PropTypes.string
}

const PrivacyPolicyPage = ({ data }) => {
  const { markdownRemark: post } = data

  return (
    <Layout>
      <PrivacyPolicyTemplate contentComponent={HTMLContent} content={post.html}/>
    </Layout>
  )
}

PrivacyPolicyPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
    contentComponent: PropTypes.func,
  }),
}

export default PrivacyPolicyPage

export const privacyPolicyPageQuery = graphql`
  query PrivacyPolicyPage {
    markdownRemark(frontmatter: { templateKey: { eq: "privacy-policy" } }) {
      html
    }
  }
`
